import { useNavigate } from "react-router-dom";
import { Article } from "../../model/blog-entity";
import "./ArticleCard.css";

interface ArticleCardProps {
  article: Article;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ article }) => {
  const navigate = useNavigate();
  return (
    <div
      className="article-card-container"
      onClick={() => navigate(`/blog/${article.id}`)}
    >
      <h2 className="aticle-title">{article.title}</h2>
      <hr />
      <p className="aticle-date">{article.date}</p>
      <img
        src={article.img_url}
        alt={`article-${article.id} image`}
        className="article-img"
      ></img>
      <p>{article.description}</p>
    </div>
  );
};

export default ArticleCard;
