import { useCart } from "../../context/CartContext";
import { CartItem } from "../../model/cart-entity";
import trash from "../../img/trash.svg";
import "./CartItemCard.css";

const CartItemCard: React.FC<{ cartItem: CartItem }> = ({ cartItem }) => {
  const { removeFromCart, increaseQuantity, decreaseQuantity } = useCart();

  return (
    <div className="cart-item-container">
      <img src={cartItem.url} alt="product-img" className="cart-item-image" />
      <div className="cart-item-name">
        <p>{cartItem.name}</p>
        <p>{cartItem.price.toFixed(2)}€</p>
      </div>
      <div className="selector-box">
        <div className="quantity-selector">
          <button
            className="quantity-btn"
            onClick={() => decreaseQuantity(cartItem.id)}
            disabled={false}
          >
            -
          </button>
          <p>{cartItem.quantity}</p>
          <button
            className="quantity-btn"
            onClick={() => increaseQuantity(cartItem.id)}
            disabled={false}
          >
            +
          </button>
        </div>
      </div>
      <b className="cart-item-price">
        {(cartItem.price * cartItem.quantity).toFixed(2)}€
      </b>
      <div className="delete-icon-box">
        <img
          src={trash}
          alt="delete item icon"
          className="delete-icon"
          onClick={() => removeFromCart(cartItem.id)}
        />
      </div>
    </div>
  );
};

export default CartItemCard;
