import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../img/logo.png";
import cartIcon from "../../img/cart.svg";
import menuIcon from "../../img/menu.svg";
import "./Header.css";
import { useCart } from "../../context/CartContext";

export default function Header() {
  const navigate = useNavigate();
  const { cart } = useCart();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const totalItems = cart.reduce((sum, item) => sum + item.quantity, 0);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      const target = event.target;
      if (
        !target.closest(".nav-links") &&
        !target.closest(".mobile-menu-icon")
      ) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleNavigation = (path: string) => {
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <div className="header">
      <div className="header-container">
        <img
          src={logo}
          alt="logo"
          className="logo-img"
          onClick={() => handleNavigation("/")}
        />

        <div className="icons-box">
          <div
            className="mobile-menu-icon"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <img src={menuIcon} alt="menu-icon" />
          </div>
          <nav className={`nav-links ${isMenuOpen ? "mobile-open" : ""}`}>
            <span className="nav-link" onClick={() => handleNavigation("/")}>
              Home
            </span>
            <span
              className="nav-link"
              onClick={() => handleNavigation("/shop")}
            >
              Shop
            </span>
            <span
              className="nav-link"
              onClick={() => handleNavigation("/blog")}
            >
              Blog
            </span>
            <span
              className="nav-link"
              onClick={() => handleNavigation("/contact")}
            >
              Kontakte
            </span>
          </nav>
          <div
            className="cart-icon-container"
            onClick={() => handleNavigation("/cart")}
          >
            <img src={cartIcon} alt="cart-icon" className="cart-icon" />
            {totalItems > 0 && <span className="cart-badge">{totalItems}</span>}
          </div>
        </div>
      </div>
    </div>
  );
}
