import React, { ReactNode } from "react";
import "./Stack.css";

interface StackProps {
  children: ReactNode;
}

const Stack: React.FC<StackProps> = ({ children }) => {
  return <div className="stack">{children}</div>;
};

export default Stack;
