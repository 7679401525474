import { useNavigate } from "react-router-dom";
import backIcon from "../../img/arrow-left.svg";
import Button from "../layout/Button";
import "./BackButton.css";

interface BackButtonProps {
  text: string;
}

const BackButton: React.FC<BackButtonProps> = ({ text }) => {
  const navigate = useNavigate();
  return (
    <Button variant="text" onClick={() => navigate(-1)}>
      <div className="back-button">
        <img src={backIcon} alt="icon" /> {text}
      </div>
    </Button>
  );
};

export default BackButton;
