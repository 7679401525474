import CartItemCard from "../../componets/shop/CartItemCard";
import { useCart } from "../../context/CartContext";
import BackButton from "../../componets/shop/BackButton";
import trash from "../../img/trash.svg";

import "./Cart.css";
import { useNavigate } from "react-router-dom";
import Button from "../../componets/layout/Button";

const Cart = () => {
  const { cart, clearCart } = useCart();

  function calculateTotalOrder() {
    return cart
      .reduce((total, item) => total + item.price * item.quantity, 0)
      .toFixed(2);
  }

  return (
    <div className="cart-page">
      <div className="cart-container">
        <h2>Warenkorb</h2>
        {cart.length === 0 ? (
          <p>Warenkorb ist leer</p>
        ) : (
          <div>
            <div className="cart-item-list">
              {cart.map((item) => (
                <CartItemCard key={item.id} cartItem={item} />
              ))}
            </div>
          </div>
        )}
        <div className="buttons-box">
          <BackButton text="Weiter einkaufen" />
          {cart.length > 0 && (
            <>
              <Button
                variant="text"
                onClick={() => {
                  clearCart();
                }}
              >
                <div className="clear-cart-button">
                  <img src={trash} alt="delete-icon" />
                  <p>Warenkorb leeren</p>
                </div>
              </Button>
              <b>Total: {calculateTotalOrder()}</b>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
