import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductById } from "../../api/product.api";
import { Product } from "../../model/product-entity";
import "./ProductDetails.css";
import Button from "../../componets/layout/Button";
import BackButton from "../../componets/shop/BackButton";
import { useCart } from "../../context/CartContext";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState<Product>();
  const [quantity, setQuantity] = useState<number>(1);
  const { addToCart } = useCart();

  useEffect(() => {
    fetchProduct();
  }, [id]);

  const fetchProduct = async () => {
    try {
      if (id) {
        const productData = await getProductById(id);
        setProduct(productData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddToCart = () => {
    if (product) {
      addToCart({
        id: product.id,
        url: product.url,
        name: product.name,
        price: product.price,
        quantity: quantity,
      });
    }
  };

  const MAX_QUANTITY = 10;
  const MIN_QUANTITY = 1;

  const handleIncrease = () => {
    setQuantity((prev) => Math.min(prev + 1, MAX_QUANTITY));
  };

  const handleDecrease = () => {
    setQuantity((prev) => Math.max(prev - 1, 0));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setQuantity(Math.min(Math.max(value, 0), MAX_QUANTITY));
    }
  };
  return (
    <div className="product-details-box">
      <BackButton text="Zurück" />
      {product && (
        <div className="product-details-container">
          <img
            className="product-details-img"
            src={product.url}
            alt="product-img"
          />
          <div className="text-box">
            <h1>{product.name}</h1>
            <p>{product.description}</p>
            <b>{product.price}€</b>
            <div className="selector-box">
              <div className="quantity-selector">
                <button
                  className="quantity-btn"
                  onClick={handleDecrease}
                  disabled={quantity <= MIN_QUANTITY}
                >
                  -
                </button>
                <input
                  type="number"
                  className="quantity-input"
                  value={quantity}
                  onChange={handleInputChange}
                />
                <button
                  className="quantity-btn"
                  onClick={handleIncrease}
                  disabled={quantity >= MAX_QUANTITY}
                >
                  +
                </button>
              </div>
              <Button onClick={handleAddToCart}>Kaufen</Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
