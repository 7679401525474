import "./Footer.css";
export default function Footer() {
  return (
    <div className="footer">
      <p className="footer-text">
        Das ist kein echter Online-Shop. Diese Website wurde als Studienprojekt
        erstellt, um die SEO-Optimierung im Rahmen der Bachelorarbeit von Maria
        Kupfer, Studentin im 7. Semester an der HTW Berlin, zu untersuchen.
      </p>
    </div>
  );
}
