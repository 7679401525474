import axios from "axios";
import { Article } from "../model/blog-entity";
import { BASE_URL } from "../context/constants";

export const getArticles = async (): Promise<Article[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/articles`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};

export const getArticleById = async (article_id: string): Promise<Article> => {
  try {
    const response = await axios.get(`${BASE_URL}/articles/${article_id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};
