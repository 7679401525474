import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Article } from "../../model/blog-entity";
import { getArticleById } from "../../api/blog.api";
import BackButton from "../../componets/shop/BackButton";
import "./ArticleDetails.css";

const ArticleDetails = () => {
  const { id } = useParams();
  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    fetchArticle();
  }, [id]);

  const fetchArticle = async () => {
    try {
      if (id) {
        const articleData = await getArticleById(id);
        setArticle(articleData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!article) {
    return null;
  }

  return (
    <div className="article-details-container">
      <div className="article-header">
        <div>
          <h1>{article?.title}</h1>
          <p>{article?.date}</p>
          <p>Autor: {article?.author}</p>
        </div>
      </div>
      <img src={article?.img_url} alt="article-img" />
      {article?.content.map((item, index) => {
        if (item.type === "header") {
          return <h2 key={item.type + index}>{item.text}</h2>;
        } else {
          return <p key={item.type + index}>{item.text}</p>;
        }
      })}
      <BackButton text="Zurück" />
    </div>
  );
};

export default ArticleDetails;
