import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FilterPanel from "../../componets/shop/FilterPanel";
import ProductList from "../../componets/shop/ProductList";
import { Product } from "../../model/product-entity";
import { getProducts, getProductsByCategory } from "../../api/product.api";
import "./Shop.css";

export default function Shop() {
  const [products, setProducts] = useState<Product[]>();
  const [selectedCategory, setSelectedCategory] =
    useState<string>("Alle Kategorien");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category") || "Alle Kategorien";
    setSelectedCategory(category);
    fetchProductsByCategory(category);
  }, [location.search]);

  const fetchProducts = async () => {
    try {
      const productsData = await getProducts();
      setProducts(productsData);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProductsByCategory = async (category: string) => {
    try {
      if (category === "Alle Kategorien") {
        fetchProducts();
      } else {
        const productsData = await getProductsByCategory(category);
        setProducts(productsData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);

    if (category === "Alle Kategorien") {
      navigate("/shop");
    } else {
      navigate(`?category=${encodeURIComponent(category)}`);
    }
  };

  return (
    <div className="shop-container">
      <FilterPanel
        selectedCategory={selectedCategory}
        setSelectedCategory={handleCategoryChange}
      />
      {products && <ProductList products={products} />}
    </div>
  );
}
