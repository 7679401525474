import filter from "../../img/filter.svg";
import close from "../../img/close-circle.svg";
import { useEffect, useState } from "react";
import "./FilterPanel.css";
import { getCategories } from "../../api/product.api";
import Button from "../layout/Button";

interface FilterPanelProps {
  selectedCategory: string;
  setSelectedCategory: (_: string) => void;
}

const FilterPanel: React.FC<FilterPanelProps> = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const ALL_CATEGORIES_TEXT = "Alle Kategorien";

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        !target.closest(".filter-panel-container") &&
        !target.closest(".filter-panel-button")
      ) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    setIsMenuOpen(false);
  };

  return (
    <div>
      <div className="filter-panel-desktop">
        <h3>Kategorien</h3>

        {categories && (
          <ul className="category-list">
            <li
              className={`category-item ${
                selectedCategory === ALL_CATEGORIES_TEXT ? "active" : ""
              }`}
              onClick={() => setSelectedCategory(ALL_CATEGORIES_TEXT)}
            >
              {ALL_CATEGORIES_TEXT}
            </li>
            {categories.map((category) => (
              <li
                key={category}
                className={`category-item ${
                  selectedCategory === category ? "active" : ""
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="filter-panel-mobile">
        <Button variant="text" onClick={() => setIsMenuOpen(true)}>
          <div className="filter-button">
            <img src={filter} /> Filtern nach Kategorie
          </div>
        </Button>

        <div className={`filter-panel-container ${isMenuOpen ? "open" : ""}`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              zIndex: "1001",
            }}
          >
            <h3>Kategorien</h3>
            <img
              src={close}
              alt="close-icon"
              onClick={() => setIsMenuOpen(false)}
            />
          </div>
          {categories && (
            <ul className="category-list">
              <li
                className={`category-item ${
                  selectedCategory === ALL_CATEGORIES_TEXT ? "active" : ""
                }`}
                onClick={() => handleCategoryClick(ALL_CATEGORIES_TEXT)}
              >
                {ALL_CATEGORIES_TEXT}
              </li>
              {categories.map((category) => (
                <li
                  key={category}
                  className={`category-item ${
                    selectedCategory === category ? "active" : ""
                  }`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </li>
              ))}
            </ul>
          )}
          <Button variant="primary" onClick={() => setIsMenuOpen(false)}>
            Schließen
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
