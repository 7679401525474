import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Shop from "./pages/shop/Shop";
import Blog from "./pages/blog/Blog";
import Header from "./componets/layout/Header";
import Footer from "./componets/layout/Footer";
import Home from "./pages/Home";
import Cart from "./pages/shop/Cart";
import ProductDetails from "./pages/shop/ProductDetails";
import { CartProvider } from "./context/CartContext";
import ArticleDetails from "./pages/blog/ArticleDetails";
import Contact from "./pages/Contact";

function App() {
  return (
    <>
      <CartProvider>
        <Router>
          <Header />
          <div className="main">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/shop/:id" element={<ProductDetails />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<ArticleDetails />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
          <Footer />
        </Router>
      </CartProvider>
    </>
  );
}

export default App;
