import React, { useRef, useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import "./ProductGallery.css";
import { Product } from "../../model/product-entity";

interface ProductGalleryProps {
  products: Product[];
}

const ProductGallery: React.FC<ProductGalleryProps> = ({ products }) => {
  const galleryRef = useRef<HTMLDivElement | null>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const updateScrollButtons = () => {
      if (galleryRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = galleryRef.current;
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
      }
    };

    const gallery = galleryRef.current;
    if (gallery) {
      updateScrollButtons();
      gallery.addEventListener("scroll", updateScrollButtons);
      return () => {
        gallery.removeEventListener("scroll", updateScrollButtons);
      };
    }
  }, []);

  const scrollLeft = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <div className="product-gallery-container">
      {canScrollLeft && (
        <button className="gallery-arrow left" onClick={scrollLeft}>
          &lt;
        </button>
      )}
      <div
        className="product-gallery"
        ref={galleryRef}
        style={{ overflowX: "auto", scrollbarWidth: "none" }}
      >
        {products.map((product) => (
          <div key={product.id} className="product-gallery-item">
            <ProductCard product={product} />
          </div>
        ))}
      </div>
      {canScrollRight && (
        <button className="gallery-arrow right" onClick={scrollRight}>
          &gt;
        </button>
      )}
    </div>
  );
};

export default ProductGallery;
