import Stack from "../componets/layout/Stack";
import banner1 from "../img/nn_banner1.webp";
import banner2 from "../img/nn_banner2.webp";
import banner3 from "../img/nn_banner3.webp";
import banner4 from "../img/nn_banner4.webp";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import { useEffect, useState } from "react";
import { getProducts } from "../api/product.api";
import { Product } from "../model/product-entity";
import ProductGallery from "../componets/shop/ProductGallery";

const Home = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<Product[]>();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const productsData = await getProducts();
      setProducts(productsData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack>
      <h1>Willkommen auf unserer Webseite für nachhaltigen Lebensstil</h1>
      <img className="img-container" src={banner1} alt="banner"></img>
      <p>
        Wir bieten eine sorgfältig ausgewählte Auswahl an nachhaltigen
        Produkten, die Ihnen helfen, einen umweltbewussteren Lebensstil zu
        führen. Von plastikfreien Alternativen für den Haushalt bis zu
        biologisch angebauten Lebensmitteln – alles, was Sie brauchen, um
        bewusster zu leben.
      </p>
      <div className="shop-blog-navbar">
        <div className="nav-box">
          <h2
            className="nav-box-title"
            onClick={() => {
              navigate("/shop");
            }}
          >
            Shop
          </h2>
          <img src={banner2} alt="shop" className="nav-box-img" />
          <div className="nav-box-button-position shop">
            <button
              className="nav-button"
              onClick={() => {
                navigate("/shop");
              }}
            >
              Zum Shop &#8594;
            </button>
          </div>
        </div>
        <div className="nav-box">
          <h2
            className="nav-box-title"
            onClick={() => {
              navigate("/blog");
            }}
          >
            Blog
          </h2>
          <img src={banner3} alt="blog" className="nav-box-img" />
          <div className="nav-box-button-position blog">
            <button
              className="nav-button"
              onClick={() => {
                navigate("/blog");
              }}
            >
              Zum Blog &#8594;
            </button>
          </div>
        </div>
      </div>

      <h2>Unsere Vision</h2>
      <p>
        Wir glauben, dass kleine Schritte große Veränderungen bewirken können.
        Mit unseren Produkten und Informationen möchten wir Sie inspirieren und
        unterstützen, bewusste Entscheidungen für einen nachhaltigeren Alltag zu
        treffen.
      </p>
      <h3>Warum Natürlich & Nachhaltig?</h3>
      <ul>
        <li>
          <strong>Qualität und Nachhaltigkeit:</strong> Unsere Produkte sind aus
          umweltfreundlichen Materialien gefertigt und fördern einen
          ressourcenschonenden Lebensstil.
        </li>
        <li>
          <strong>Inspirierende Inhalte:</strong> Unsere Blogartikel bieten
          praktische Tipps und Hintergrundwissen, um Ihnen den Einstieg in einen
          nachhaltigen Lebensstil zu erleichtern.
        </li>
        <li>
          <strong>Gemeinschaft:</strong> Wir schaffen eine Plattform, auf der
          Gleichgesinnte ihre Erfahrungen teilen und voneinander lernen können.
        </li>
      </ul>
      <p>
        Werden Sie Teil unserer Mission, eine nachhaltigere Welt zu schaffen.
        Jeder Beitrag zählt, und gemeinsam können wir die Zukunft positiv
        gestalten.
      </p>
      {products && <ProductGallery products={products} />}
      <img
        style={{ width: "100%", height: "350px", objectFit: "cover" }}
        src={banner4}
        alt="banner"
      />
      <p>
        Lassen Sie uns zusammen die kleinen Veränderungen angehen, die einen
        großen Unterschied machen. Machen Sie mit und gestalten Sie die Zukunft
        nachhaltiger – für sich selbst, für andere und für unseren Planeten.
      </p>
    </Stack>
  );
};

export default Home;
