import { useNavigate } from "react-router-dom";
import { Product } from "../../model/product-entity";
import Button from "../layout/Button";
import "./ProductCard.css";
import { useCart } from "../../context/CartContext";

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const { addToCart } = useCart();
  const navigate = useNavigate();

  const handleAddToCart = () => {
    addToCart({
      id: product.id,
      url: product.url,
      name: product.name,
      price: product.price,
      quantity: 1,
    });
  };

  return (
    <div className="product-card-container">
      <div
        onClick={() => {
          navigate(`/shop/${product.id}`);
        }}
      >
        <div className="product-img-box">
          <img
            src={product.url}
            alt="product img"
            className="product-img"
          ></img>
        </div>
        <h3>{product.name}</h3>
        <p>{product.description}</p>
      </div>

      <div className="product-card-button">
        <p className="product-price">
          <b>{product.price.toFixed(2)}€</b>
        </p>
        <Button onClick={handleAddToCart}>Kaufen</Button>
      </div>
    </div>
  );
};

export default ProductCard;
