const Contact = () => {
  return (
    <div>
      <h1>Herzlich willkommen auf die Kontaktseite!</h1>
      <p>
        Bitte beachten Sie, dass dies kein echter Online-Shop ist. Diese Website
        wurde als Studienprojekt erstellt, um die SEO-Optimierung im Rahmen der
        Bachelorarbeit von Maria Kupfer, Studentin im 7. Semester an der HTW
        Berlin, zu untersuchen. Falls Sie Fragen haben, können Sie mir jederzeit
        unter folgender E-Mail-Adresse erreichen:
        Maria.Kupfer@Student.HTW-Berlin.de. Alle Bilder wurden von KI generiert.
      </p>
    </div>
  );
};

export default Contact;
