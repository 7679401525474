import { useEffect, useState } from "react";
import { getArticles } from "../../api/blog.api";
import { Article } from "../../model/blog-entity";
import ArticleCard from "../../componets/blog/ArticleCard";
import "./Blog.css";

const Blog = () => {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    getArticles().then((response) => {
      if (response) {
        setArticles(response);
      }
    });
  }, []);
  return (
    <div className="articles-list-container">
      {articles.map((article) => (
        <ArticleCard key={article.id} article={article} />
      ))}
    </div>
  );
};

export default Blog;
